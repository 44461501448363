export const DestNameKerala = "Kerala";
export const DestNameDubai = "Dubai";
export const DestNameKarnataka = "Karnataka";
export const DestNameKashmir = "Kashmir";
export const DestNameHimachal = "Himachal";
export const DestNameUttrakhand = "Uttrakhand";
export const DestNameRajasthan = "Rajasthan";
export const DestNameSikkimDarjeeling = "Sikkim_Darjeeling";
export const DestNameGoa = "Goa";
export const DestNameBali = "Bali";
export const DestNameNepal = "Nepal";
export const DestNameBhutan = "Bhutan";
export const DestNameVietnam = "Vietnam";
export const DestNameMaharashtra = "Maharashtra";
export const DestNamePuducherry = "Puducherry";
export const DestNameTamilNadu = "Tamil Nadu";
export const DestNameThailand = "Thailand";
export const DestNameEurope = "Europe";
export const DestNameTurkey = "Turkey";
export const DestNameSriLanka = "SriLanka";
export const DestNameAndaman = "Andaman";
export const DestNameMeghalaya = "Meghalaya";
export const DestNameAssam = "Assam";
export const DestNameArunanchal = "Arunanchal Pradesh";
export const DestNameOthers = "Others";

export const DestinationNames = [
  DestNameKerala,
  DestNameKarnataka,
  DestNameKashmir,
  DestNameHimachal,
  DestNameSikkimDarjeeling,
  DestNameDubai,
  DestNameBhutan,
  DestNameUttrakhand,
  DestNameRajasthan,
  DestNameMaharashtra,
  DestNameGoa,
  DestNameNepal,
  DestNamePuducherry,
  DestNameBali,
  DestNameVietnam,
  DestNameTamilNadu,
  DestNameThailand,
  DestNameEurope,
  DestNameTurkey,
  DestNameSriLanka,
  DestNameAndaman,
  DestNameMeghalaya,
  DestNameAssam,
  DestNameArunanchal

];
export const BookingStatusAll = "all";
export const BookingStatusActive = "active";
export const BookingStatusCompleted = "completed";
export const BookingStatusCancelled = "cancelled";

export const BookingTypes = [
  BookingStatusActive,
  BookingStatusCompleted,
  BookingStatusCancelled,
  BookingStatusAll,
];

export const CabTypes = ["Sedan", "Suv", "Hatchback", "Traveller", "Bus"];

export const BID_DECLINED_STATUS_TYPE = "declined";
export const BID_DEFAULT_STATUS_TYPE = "pending";
export const BID_ACCEPTED_STATUS_TYPE = "accepted";
export const REQ_ACTIVE_STATUS_TYPE = "active";
export const REQ_EXPIRED_STATUS_TYPE = "expired";
export const REQ_BOOKED_STATUS_TYPE = "booked";
export const BOOKING_ACTIVE_STATUS_TYPE = "active";
export const BOOKING_CANCELLED_STATUS_TYPE = "cancelled";
// export const BOOKING_CANCELLED_STATUS_TYPE = 'cancelled';
export const MAX_TEXT_LENGTH_OTHERINFO = 2000;

export const REQ_DETAIL_CARD_FONT_MOBILE = "0.79rem";
export const REQ_DETAIL_CARD_FONT_DESKTOP = "0.79rem";
export const REQ_EXPIRE_TIMEOUT_DAYS = 15; //1000;
export const BOOKING_EXPIRE_TIMEOUT_DAYS = 3;
export const KYC_PENDING_LINK =
  process.env.NODE_ENV === "production"
    ? "https://cabeasy.in/kyc-pending"
    : "https://persuasive-ego-377212.web.app/kyc-pending";
export const BOOKING_CHAT_TYPE_SYSTEM = "cb-system";
export const KYC_FORM_LINK = "https://forms.gle/tR1p39JZH2afo1NG8";  //"https://forms.gle/Lie3XbB8hFQEC4Ts7";
export const BOOKING_STATUS_STEPS = [
  {
    label: "Arrival/Hotel Details recieved",
    from: "agent",
    trackingFields: ["arrivalDetailsRecieved"],
    step: 1,
  },
  {
    label: "Driver&Cab Details Recieved",
    from: "supplier",
    trackingFields: ["driverDetailsRecieved"],
    step: 2,
  },
  {
    label: "Payment Recieved",
    from: "agent",
    trackingFields: ["completePaymentRecieved"],
    step: 3,
  },
  {
    label: "Trip Completed",
    from: "supplier",
    trackingFields: [
      "supplierMarkComplete",
      "agentMarkComplete"
    ],
    step: 4,
  },
  // {
  //   label: 'Feedback shared',
  //   from: 'supplier'
  // },
  // {
  //   label: 'Feedback shared',
  //   from: 'agent'
  // }
];

export const WHATSAPP_FORUM_LINK_AGENT = "https://bit.ly/CabEasyForum";
export const WHATSAPP_FORUM_LINK_SUPPLIER = "https://bit.ly/49I7y17";
export const PHONE_FILTER_REGEX = /(?:\+?\d{1,3}[-.\s]?)?[\s\d]{10,14}/g;
export const BID_MSGS_MAX_LENGTH = 100;
export const BID_DECLINE_REASONS_TYPES = [
  {
    label: "Bid Too high!",
    value: "bid_too_high"
  },
  {
    label: "Incorrect Bid!",
    value: "bid_incorrect"
  },
  {
    label: "Bid Details not clear",
    value: "need_details"
  },
  {
    label: "Something else",
    value: "none_of_the_above"
  }
]
export const BOOKING_CANCEL_REASONS_TYPES = [
  {
    label: "Cusomization Not Included",
    value: "no_customization"
  },
  {
    label: "Have Better Price",
    value: "better_price"
  },
  {
    label: "Incorrect Price",
    value: "price_incorrect"
  },
  {
    label: "Something else",
    value: "none_of_the_above"
  }
]


export const PRIORITY_TEST = "dest_not_confirmed"
export const PRIORITY_FLEX = "dest_confirmed"
export const PRIORITY_BOOK = "tickets_booked"

export const PRIORITY_OPT = {
  [PRIORITY_TEST]: 'Query Not Confirmed',
  [PRIORITY_FLEX]: 'Flexible Dates',
  [PRIORITY_BOOK]: 'Query Confirmed',
}

export const PRIORITY_COLOR={
  [PRIORITY_TEST]: 'gray',
  [PRIORITY_FLEX]: '#ffb833',
  [PRIORITY_BOOK]: 'yellowgreen',
}

export const CabEasyAccDetails = {
	accName: "Ashlab Technology Pvt Ltd",
	bankName: "Axis",
	branch: "TODO",
	accNo: "924020054317030",
	ifsc: "UTIB0004619"
}

export const FDPaymentAccDetails = {
	accName: "ANNEX INDIA TOUR",
	bankName: "HDFC",
	branch: "ROHINI SECTOR 8",
	accNo: "50200073902160",
	ifsc: "HDFC0000886"
}

export const FDPaymentGpayNo = "+919354078956";
export const CabEasyPayGpayNo = "+919266830896";
export const CB_SERVICE_GUARENTEE_FEE_INR = "99";
export const PBO_STEP_CONFIRM_ITI = "confirm_itinerary";
export const PBO_STEP_ARRIVAL_DETAILS = "submit_arrival_details";